import { template as template_a8468b8df1ae41f4801bc2c7b8c55953 } from "@ember/template-compiler";
const FKText = template_a8468b8df1ae41f4801bc2c7b8c55953(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
