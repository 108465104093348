import { template as template_b5207907c4e94bf4ab681d391f94d0bc } from "@ember/template-compiler";
const FKControlMenuContainer = template_b5207907c4e94bf4ab681d391f94d0bc(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
