import { template as template_11e21771d87a4f698e43de4d71cbc882 } from "@ember/template-compiler";
const WelcomeHeader = template_11e21771d87a4f698e43de4d71cbc882(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
